import React, { useState, useEffect, useRef } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
    BrowserRouter as Router,
    NavLink,
    Route,
    Link,
    useLocation,
    useParams
} from "react-router-dom";
import {Helmet} from 'react-helmet';

import {
    isMobile,
    isIOS, // ------ utiliser pour choisir le lien via Apple Store 
    isAndroid // ------ utiliser pour choisir le lien via Google Play
  } from "react-device-detect";  

// @material-ui/icons

// core components
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Parallax from "components/Parallax/Parallax.js";
import GridList from '@material-ui/core/GridList';
import { BottomSheet } from "react-spring-bottom-sheet";

import AuthService from "../../services/auth.service.js";
import PropTypes from 'prop-types';
import { getByIdPublic } from '../../API/ItemsByIdPublic';
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import CircularProgress from '@material-ui/core/CircularProgress';
import Carousel from 'components/CarouselMulti/Carousel';
import useWindowSize from 'components/useWindowSize';
import { Popover } from "@material-ui/core";

import { Box } from "@material-ui/core";
import { ReactVideo } from '../../components/Video/Video.js';
import exclusifLarge from "assets/img/exclusif_large.png";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import sofaLogo from "assets/img/sofa-blanc_final.png";
import noImageAVIF from "assets/img/Fond_lampadaire.avif";
import noImage from "assets/img/Fond_lampadaire.jpg";

import logoApp from "assets/img/Logo_App.png";
import moment from "moment";
require('moment/locale/fr');

moment.locale('fr');

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
});
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "transparent",
    padding: theme.spacing(2),
    textAlign: 'center',
    boxShadow: 'unset'

}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={2}>{children}</Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(styles);
const useStylesList = makeStyles((theme) => ({
  listContainer: {
    justifyContent: 'flex-start',
    height: 260,
    width: "100vw !important",
    margin: "0 0 0 -90px",
    paddingRight: 18
  },
  listContainerM: {
    justifyContent: 'flex-start',
    height: 260,
    margin: "0 -15px",
    width: "100vw !important"
  },
  title: {
    textTransform: "uppercase",
    fontSize: "2em"
  },
  mobilePopUp: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    background: "#FFF",
    "& button": {
      display: "flex",
      marginBottom: 10,
      padding: 10,
      alignItems: "center",
      textDecoration: "none",
      color: "#000",
      border: "none",
      outline: "none",
      "& img": {
        marginRight: 10,
        borderRadius: 10
      },
      "& p": {
        margin: 0
      }
    }
  },
  sofaLogo: {
    position: "absolute",
    top: 10,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    zIndex: 1
  }
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ItemShare(props) {
    const location = useLocation()
    const nWidthPeople = 120;
    const nHeightPeople = 120;
    const classes = useStyles();
    const classesList = useStylesList();
    const [isLoading, setLoading] = useState(true);
    const { ...rest } = props;
    const { width } = useWindowSize();
    let item = {};
    let tabActorDataTemp = [];
    let tabRealDataTemp = [];
    let tabTechDataTemp = [];
    const [idItem, setIdItem] = useState(null);
    const [tabSeason, setTabSeason] = useState([]);
    const [tabProductor, setTabProductor] = useState([]);
    const [tabRegion, setTabRegion] = useState([]);
    const [tabGenre, setTabGenre] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);
    const share = open ? 'simple-popover' : undefined;
    const copied = open2 ? 'simple-popover' : undefined;
  

    const [respItem, setRespItem] = useState({});

    const [tabActorData, setTabActorData] = useState([]);
    const [tabRealData, setTabRealData] = useState([]);
    const [tabTechData, setTabTechData] = useState([]);
    const [value, setValue] = useState(0);
    const [tabAge, setTabAge] = useState([]);
    //const offset = useRef(new Animated.Value(0)).current;
    const [seasons, setSeasons] = useState([]);
    const [mobilePopUp, setMobilePopUp] = useState(false);
    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid
    );
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

    let currentUser = AuthService.getCurrentUser();

    const fetchData = async () => {
        const respItemC = await getByIdPublic(props.match.params.id)

        respItemC.data.map(function (itemLocal) {
            item = itemLocal;

            setIdItem(itemLocal.Id);
            setTabSeason(itemLocal.Table_Seasons.sort((a, b) => a.number > b.number ? 1 : -1));
            setTabProductor(itemLocal.Table_Productor);
            setTabGenre(itemLocal.categories);
            setTabRegion(itemLocal.Table_Region);
            setTabAge(itemLocal.Table_Age_Limit);

            if (itemLocal.idType == 1) {
                let saisons = [];
                itemLocal.Table_Seasons.forEach(element => {
                    let newSaison = true;
                    saisons.forEach(element2 => {
                        if (element2.number == element.season) {
                            newSaison = false;
                        }
                    })
                    if (newSaison == true) {
                        saisons.push({ number: element.season })
                    }
                });
                setSeasons(saisons.sort((a, b) => a.number > b.number ? 1 : -1));
            }
            itemLocal.Table_People_Projects.map(function (people) {

                let oPeople = people.Table_Person;

                if (people.idFunction == 3) {
                    tabActorDataTemp.push({ name: oPeople.name, photo_path: oPeople.photo_path, function: people.description, id: oPeople.id });
                }
                if (people.idFunction == 1) {
                    tabRealDataTemp.push({ name: oPeople.name, photo_path: oPeople.photo_path, function: people.description, id: oPeople.id });
                }
                if (people.idFunction == 2) {
                    tabRealDataTemp.push({ name: oPeople.name, photo_path: oPeople.photo_path, function: people.description, id: oPeople.id });
                }
                if (people.idFunction == 4) {
                    tabTechDataTemp.push({name : oPeople.name, photo_path: oPeople.photo_path, function:people.description, id:oPeople.id}); 
                }

            });
            setRespItem(itemLocal);
            setTabRealData(tabRealDataTemp);
            setTabActorData(tabActorDataTemp);
            setTabTechData(tabTechDataTemp);
        });
        setLoading(false)

    }


    useEffect(() => {
        const getAPI = async () => {

            setLoading(true);
            setSeasons([]);



            fetchData()

        }
        getAPI();

        if (isMobile) {
            setTimeout(() => {
                verifyPopUpMobile();
            }, 1000)
        }

    }, [location.key]);

    const verifyPopUpMobile = () => {
        if (document.getElementById("tarteaucitronAlertBig").style.display != "none") {
            setTimeout(() => {
                verifyPopUpMobile()
            }, 1000)
        } else {
            setMobilePopUp(true);
        }
    }

    const getPeopleColumns = () => {
      let nPeople = width / 200;
      return Math.trunc(nPeople);
    }

    const getLargePeopleColums = () => {
        let nPeople = width / 300;
        return Math.trunc(nPeople);
    }    

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      }
    
      const handleClick2 = (event) => {
        const text = "https://sofavod.com/itemShare/" + idItem;
        navigator.clipboard.writeText(text).then(
          () => {
            setAnchorEl2(event.target);
            setTimeout(() => {
              handleClose2();
            }, 500)
          }
        );
      }
    
      const handleClose = () => {
        setAnchorEl(null);
      }
    
      const handleClose2 = () => {
        setAnchorEl2(null);
      }
    
      const onDismiss = () => {
        setMobilePopUp(false);
      }

      const clickEvent = () => {
        if (isAndroid) {
            window.location.replace("intent://sofavod.com/#Intent;scheme=https;package=com.kevingraillot.sofa;end");
        } else if (isIOS) {
            if (respItem.statut == 20) {
                window.location.replace("com.kevingraillot.sofa://item/" + idItem);
            } else {
                window.location.replace("com.kevingraillot.sofa://");
            }

            setTimeout(() => {
                window.location.replace("https://apps.apple.com/fr/app/sofa-vod/id1525885609");
            }, 5000)
        }
        onDismiss();
      }

      const defaultImage = (style, width, height, title, clas) => {
        let picture = document.createElement("picture");
        picture.setAttribute("class", classes.defaultImgPicture + " " + classes.imageEpisode);
        let source = document.createElement("source");
        source.setAttribute("srcSet", noImageAVIF);
        source.setAttribute("type", "image/avif");
        let img = document.createElement("img");
        img.setAttribute("src", noImage);
        let jsonStyle = {};
        Object.values(JSON.parse(JSON.stringify(style))).forEach(element => {
          jsonStyle[element] = style[element];
        })
        img.setAttribute("style", JSON.stringify(jsonStyle).replace(/","/g, ";").replace(/["'{}]/g, ""));
        img.setAttribute("width", width);
        img.setAttribute("height", height);
        img.setAttribute("class", clas)
        let div = document.createElement("div");
        div.setAttribute("class", classes.defaultImgDiv);
        let text = document.createElement("h3");
        text.setAttribute("class", classes.defaultImgText);
        text.appendChild(document.createTextNode(title));
        div.appendChild(text);
        picture.appendChild(source);
        picture.appendChild(img);
        picture.appendChild(div);
        return picture;
      }
    
      const replaceTitle = (title) => {
        let div1 = document.createElement("div");
        div1.setAttribute("class", classes.replaceTextDiv1);
        let div2 = document.createElement("div");
        div2.setAttribute("class", classes.replaceTextDiv2);
        let text = document.createElement("h1");
        text.setAttribute("class", classes.title + " " + classes.replaceText);
        text.appendChild(document.createTextNode(title));
        div2.appendChild(text);
        div1.appendChild(div2);
        return div1;
      }
    
    if (respItem) {
        return (
            <div className={classes.mainBackground}>
                <Helmet>
                    <title>{respItem.title}</title>
                    <meta name="description" content={respItem.overview} />
                    <meta property="og:url" content={"https://sofavod.com/itemShare/" + idItem} />
                    <meta property="og:title" content={respItem.title} />
                    <meta property="og:description" content={respItem.overview} />
                    <meta property="og:image" content={respItem.mini_title_path + "?tr=w-1200,h-630,fo-auto"} />
                    <meta name="twitter:title" content={respItem.title} />
                    <meta name="twitter:description" content={respItem.overview} />
                    <meta name="twitter:image" content={respItem.mini_title_path + "?tr=w-1200,h-630,fo-auto"} />
                </Helmet>
                <div className={classesList.sofaLogo}>
                    <a href="/"><img src={sofaLogo} height={60} /></a>
                </div>
                {isLoading ? <div className={classes.container}>
                    <div className={classes.loading}>
                        <CircularProgress />
                    </div>
                </div> : (
                <div>
                    <Parallax filter image={width >= 1920 ? encodeURI(respItem.mini_path) : width > 640 ? encodeURI(respItem.mini_path + "?tr=w-" + width + ",h-" + ((width*1080)/1920) + ",dpr-" + window.devicePixelRatio.toString()) : respItem.portrait_path ? encodeURI(respItem.portrait_path + "?tr=w-" + width + ",h-" + ((width*3000)/2000) + ",dpr-" + window.devicePixelRatio.toString()) : encodeURI(respItem.mini_path + "?tr=w-" + width + ",h-" + ((width*1080)/1920) + ",dpr-" + window.devicePixelRatio.toString())}>
                      <div className={classes.container}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            {respItem.logo_path ?
                              <img src={respItem.logo_path + "?tr=w-" + (width > 1200 ? (1140*0.5).toString() + ",h-" + ((1140*0.5)*9/16).toString() : (width*0.5).toString() + ",h-" + ((width*0.5)*9/16).toString()) + ",dpr-" + window.devicePixelRatio.toString()} alt={respItem.title} width="100%" loading="lazy" onError={(e) => {e.target.replaceWith(replaceTitle(respItem.title))}} /> : <div className={classes.titleTextContainer}><div><h1 className={classes.title}>{respItem.title}</h1></div></div>
                            }
                            {/*<h4>
                              {respItem.short_desc}
                            </h4>*/}
                            <div className={classes.presentationElement}>
                                {currentUser && currentUser.statut == 20 && respItem.statut == 20 ? <Link to={{
                                    pathname: "/player",
                                    state: respItem
                                }}><Button
                                    color={width > 640 ? "sofa" : "sofaM"}
                                    size={width > 640 ? "lg" : "sm"}
                                    //  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                ><i className="fa-regular fa-circle-play fa-xl" /> &nbsp;  &nbsp;Regarder sur SOFA</Button>
                                </Link> : respItem.statut == 20 ? <a href="https://moncompte.sofavod.com/web/signup?redirect=https%3A%2F%2Fmoncompte.sofavod.com%2Fshop"><Button
                                color={width > 640 ? "sofa" : "sofaM"}
                                size={width > 640 ? "lg" : "sm"}
                                //  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                                target="_blank"
                                rel="noopener noreferrer"
                                >S'abonner pour visionner</Button></a> : <Link to={{pathname: "/"}}>
                                  <Button
                                    color={width > 640 ? "sofa" : "sofaM"}
                                    size={width > 640 ? "lg": "sm"}
                                  >{respItem.statut < 20 && respItem.startDate && moment(respItem.startDate).isAfter(moment()) ? "Sera disponible le " + moment(respItem.startDate).format("Do MMMM YYYY") : 
                                  respItem.statut < 20 && "Prochainement disponible"}
                                  {respItem.statut > 20 && "Indisponible"}</Button>
                                </Link>}
                                <div className={classes.interactList}>
                                  <div>
                                    <button aria-describedby={share} onClick={handleClick} variant="contained" className={classes.interactButton}>
                                      <i class={width > 640 ? "fas fa-share fa-xl" : "fas fa-share"}></i><p className={classes.interactText}>Partager</p>
                                    </button>
                                    <Popover
                                      id={share}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left"
                                      }}
                                    >
                                      <div className={classes.popOverElement}>
                                        <p>Lien de partage :</p>
                                        <input type="text" value={"https://sofavod.com/itemShare/" + respItem.Id} autoFocus readOnly></input>
                                        <button aria-describedby={copied} variant="contained" className={classes.copyButton} onClick={handleClick2}>Copier</button>
                                        <Popover 
                                          id={copied}
                                          open={open2}
                                          anchorEl={anchorEl2}
                                          onClose={handleClose2}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left"
                                          }}
                                        >
                                          <p className={classes.popOverText}>Lien copié !</p>
                                        </Popover>
                                      </div>
                                    </Popover>
                                  </div>
                              </div>
                            </div>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6} className={classes.BAContainer}>
                            {respItem.teaser_path && width >= 840 &&
                              <div className={classes.BAContainerDiv}>
                                <ReactVideo
                                  autoPlay={true}
                                  width='100%'
                                  // height="300"
                                  // poster={respItem.mini_path}
                                  controls={true}
                                  idEpisode={respItem.idEpisode}
                                  idItem={respItem.Id}
                                  user={currentUser}
                                  src={respItem.teaser_path}
                                />
                              </div>
                            }
                          </GridItem>
                        </GridContainer>
                      </div>
                    </Parallax>
        
                    <div className={classes.main + " " + classes.blurBackground} style={{ backgroundImage: "url(" + respItem.mini_path + "?tr=bl-50)" }}>
                      <div className={width > 960 ? classes.darkBackground : classes.darkBackgroundM}>
                        <GridContainer className={classes.mainContainer}>
                          <GridItem className={classes.tabsContainer}>
                            <Tabs TabIndicatorProps={{ style: { background: '#ec6f39' } }} value={value} onChange={handleChange}>
                              <Tab label="Informations" {...a11yProps(0)} />
                              {seasons.map(season => (
                                <Tab label={"Saison " + season.number} {...a11yProps(season.number)} />
                              ))}
                            </Tabs>
                          </GridItem>
                          <TabPanel value={value} index={0}>
                            <GridItem xs={12}>
                              <div className={width > 720 ? classes.informationContainer : classes.informationContainerM}>
                                <div className={width > 720 ? classes.descriptionContainer : classes.descriptionContainerM}>
                                  <div className={classes.description}>
                                    <p className={classes.textDescription}>
                                      {respItem.overview}
                                    </p>
                                  </div>
                                  {tabProductor ?
                                    <div className={classes.clickableItem}>
                                      <div className={classes.productorElement}>
                                        <img src={encodeURI(tabProductor.photo_path + "?tr=w-" + (width > 640 ? "120,h-120" : "90,h-90") + ",dpr-" + window.devicePixelRatio.toString())} width={width > 640 ? 120 : 90} height={width > 640 ? 120: 90} alt={tabProductor.photo_path} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                                        <p>Produit par<br />{ tabProductor.name }</p>
                                      </div>
                                    </div> : (
                                      <div></div>
                                    )
                                  }
                                </div>
                                <div className={width > 720 && classes.logoContainer}>
                                  <div className={classes.tabLogo}>
                                    {respItem.isExclusif == 1 &&
                                      <img src={exclusifLarge} className={classes.exclusifLogo} height={30} alt="Exclusif" />
                                    }
                                    {currentUser && currentUser.statut != 20 && item.isFree != 1 &&
                                      <div className={classes.freeLogo} style={{ marginRight: 10 }}>
                                        <i class="fa-solid fa-lock fa-2xl" title={item.idType == 1 ? "Vous pouvez regarder le 1er épisode de cette série gratuitement." : "Vous devez avoir un abonnement pour visionner ce film."}></i>
                                      </div>
                                    }
                                    {respItem.format == "portrait" &&
                                      <div className={classes.portraitLogo}>
                                        <i className="fa-solid fa-mobile-screen fa-2xl" title="Ce film est au format portrait" />
                                      </div>
                                    }
                                    {tabAge &&
                                      <img src={encodeURI(tabAge.image_path + "?tr=w-40,h-40,dpr-" + window.devicePixelRatio.toString())} alt={tabAge.name} className={classes.ageLogo} width={39.2} height={39.2} loading="lazy" />
                                    }
                                    <h6 className={classes.timeText}>{respItem.time}</h6>
                                  </div>
                                  <div className={classes.containerLogo}>
                                    <GridList className={classes.gridList + " " + classes.listGenre}>
                                      {tabGenre.map(genre => (
                                        <div className={classes.genreItem}>
                                          <div className={classes.categoryItem}>
                                            <img src={encodeURI(genre.url + "?tr=w-120,h-120,dpr-" + window.devicePixelRatio.toString())} className={classes.categoryImg} width={120} height={120} alt={genre.url} loading="lazy" />
                                            <div className={classes.categoryLogoDiv}>
                                              <img src={encodeURI(genre.imageLogo + "?tr=w-120,h-" + (120*9/16).toString() + ",dpr-" + window.devicePixelRatio.toString())} width={120} height={120*9/16} alt={genre.imageLogo} loading="lazy" />
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </GridList>
                                  </div>
                                  <div className={classes.containerLogo}>
                                    <div className={classes.clickableItem}>
                                        <div className={classes.departementDiv2}>
                                            <img src={encodeURI(tabRegion.logo_path + "?tr=w-120,h-120,dpr-" + window.devicePixelRatio.toString())} className={classes.departementImg} width={120} height={120} alt={tabRegion.name} loading="lazy" />
                                            <h6 className={classes.departementText}>{tabRegion.name}</h6>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </GridItem>
                            {tabRealData.length > 0 &&
                              <GridItem xs={12} className={classes.containerPeopleList}>
                                <h4 className={classes.title + " " + classesList.title}>Réalisation</h4>
                                <div className={width > 960 ? classes.root + " " + classesList.listContainer : classes.root + " " + classesList.listContainerM}>
                                  {tabRealData.length > getLargePeopleColums() ?
                                  (tabRealData.length >= getPeopleColumns() ? 
                                    <Carousel show={getPeopleColumns()}>
                                      {tabRealData.map(people => (
                                        <div className={classes.peopleItem}>
                                          <div className={width >= 840 ? classes.peopleDiv : classes.peopleDivM}>
                                            <img src={encodeURI(people.photo_path + "?tr=w-" + nWidthPeople + ",h-" + nWidthPeople + ",dpr-" + window.devicePixelRatio.toString())} width={nWidthPeople} height={nHeightPeople} alt={people.photo_path} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                                            <h6>{people.name}</h6>
                                            <p>{people.function}</p>
                                          </div>
                                        </div>
                                      ))}
                                    </Carousel> : <GridList className={classes.gridList + " " + classes.listPeople}>
                                      {tabRealData.map(people => (
                                        <div className={classes.peopleItem}>
                                          <div className={width >= 840 ? classes.peopleDiv : classes.peopleDivM}>
                                            <img src={encodeURI(people.photo_path + "?tr=w-" + nWidthPeople + ",h-" + nWidthPeople + ",dpr-" + window.devicePixelRatio.toString())} width={nWidthPeople} height={nHeightPeople} alt={people.photo_path} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                                            <h6>{people.name}</h6>
                                            <p>{people.function}</p>
                                          </div>
                                        </div>
                                      ))}
                                    </GridList>) : (
                                      <GridList className={width > 640 ? classes.gridList + " " + classes.listLargePeople : classes.gridList + " " + classes.listLargePeopleM}>
                                        {tabRealData.map(people => (
                                          <div className={classes.largePeopleItem}>
                                              <div className={classes.largePeopleDiv}>
                                                <img src={encodeURI(people.photo_path + "?tr=w-" + nWidthPeople + ",h-" + nWidthPeople + ",dpr-" + window.devicePixelRatio.toString())} width={nWidthPeople} height={nHeightPeople} alt={people.photo_path} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                                                <div>
                                                  <h6>{people.name}</h6>
                                                  <p>{people.function}</p>
                                                </div>
                                              </div>
                                          </div>
                                        ))}
                                      </GridList>
                                    )
                                  }
                                </div>
                              </GridItem>}
                            {tabActorData.length > 0 &&
                              <GridItem xs={12} className={classes.containerPeopleList}>
                                <h4 className={classes.title + " " + classesList.title}>Casting</h4>
                                <div className={width > 960 ? classes.root + " " + classesList.listContainer : classes.root + " " + classesList.listContainerM}>
                                  {tabActorData.length > getLargePeopleColums() ?
                                  (tabActorData.length >= getPeopleColumns() ?
                                    <Carousel show={getPeopleColumns()}>
                                      {tabActorData.map(people => (
                                        <div className={classes.peopleItem}>
                                          <div className={width >= 840 ? classes.peopleDiv : classes.peopleDivM}>
                                            <img src={encodeURI(people.photo_path + "?tr=w-" + nWidthPeople + ",h-" + nWidthPeople + ",dpr-" + window.devicePixelRatio.toString())} width={nWidthPeople} height={nHeightPeople} alt={people.photo_path} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                                            <h6>{people.name}</h6>
                                            <p>{people.function}</p>
                                          </div>
                                        </div>
                                    ))}
                                    </Carousel> : <GridList className={classes.gridList + " " + classes.listPeople}>
                                      {tabActorData.map(people => (
                                        <div className={classes.peopleItem}>
                                          <div className={width >= 840 ? classes.peopleDiv : classes.peopleDivM}>
                                            <img src={encodeURI(people.photo_path + "?tr=w-" + nWidthPeople + ",h-" + nWidthPeople + ",dpr-" + window.devicePixelRatio.toString())} width={nWidthPeople} height={nHeightPeople} alt={people.photo_path} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                                            <h6>{people.name}</h6>
                                            <p>{people.function}</p>
                                          </div>
                                        </div>
                                    ))}
                                    </GridList>) : (
                                      <GridList className={width > 640 ? classes.gridList + " " + classes.listLargePeople : classes.gridList + " " + classes.listLargePeopleM}>
                                        {tabActorData.map(people => (
                                          <div className={classes.largePeopleItem}>
                                              <div className={classes.largePeopleDiv}>
                                                <img src={encodeURI(people.photo_path + "?tr=w-" + nWidthPeople + ",h-" + nWidthPeople + ",dpr-" + window.devicePixelRatio.toString())} width={nWidthPeople} height={nHeightPeople} alt={people.photo_path} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                                                <div>
                                                  <h6>{people.name}</h6>
                                                  <p>{people.function}</p>
                                                </div>
                                              </div>
                                          </div>
                                        ))}
                                      </GridList>
                                    )
                                  }
                                </div>
                              </GridItem>}
                            {tabTechData.length > 0 &&
                              <GridItem xs={12} className={classes.containerPeopleList}>
                                <h4 className={classes.title + ' ' + classesList.title}>Équipe Technique</h4>
                                <div className={width > 960 ? classes.root + " " + classesList.listContainer : classes.root + " " + classesList.listContainerM}>
                                  {tabTechData.length >= getPeopleColumns() ?
                                    <Carousel show={getPeopleColumns()}>
                                      {tabTechData.map(people => (
                                        <div className={classes.peopleItem}>
                                          <div className={width >= 840 ? classes.peopleDiv : classes.peopleDivM}>
                                            <img src={encodeURI(people.photo_path + "?tr=w-" + nWidthPeople + ",h-" + nWidthPeople + ",dpr-" + window.devicePixelRatio.toString())} width={nWidthPeople} height={nHeightPeople} alt={people.photo_path} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                                            <h6>{people.name}</h6>
                                            <p>{people.function}</p>
                                          </div>
                                        </div>
                                      ))}
                                    </Carousel> : (
                                    <GridList className={classes.gridList + " " + classes.listPeople}>
                                      {tabTechData.map(people => (
                                        <div className={classes.peopleItem}>
                                          <div className={width >= 840 ? classes.peopleDiv : classes.peopleDivM}>
                                            <img src={encodeURI(people.photo_path + "?tr=w-" + nWidthPeople + ",h-" + nWidthPeople + ",dpr-" + window.devicePixelRatio.toString())} width={nWidthPeople} height={nHeightPeople} alt={people.photo_path} loading="lazy" onError={(e) => {e.target.src = "https://ik.imagekit.io/sofavod/sofa-Sofa_Personna_noir-1605714448075.png?tr=w-250,h-250"}} />
                                            <h6>{people.name}</h6>
                                            <p>{people.function}</p>
                                          </div>
                                        </div>
                                      ))}
                                    </GridList>)}
                                </div>
                              </GridItem>}
                          </TabPanel>
                          {seasons.map(season => (
                            <TabPanel value={value} index={season.number}>
                              {tabSeason.map(episode =>
                              (episode.season == season.number &&
                                <Paper className={classes.episodeContainer} sx={{ p: 2, margin: 'auto', flexGrow: 1, elevation: 25 }}>
                                  <Grid container spacing={2} className={width <= 840 && classes.episodeContainerDivM}>
                                    <Grid item className={classes.imageEpisodeElement}>
                                      <ButtonBase sx={{ width: 240, height: 135, borderRadius: "15px" }}>
                                        <Img className={classes.imageEpisode} alt={episode.titleEpisode} src={encodeURI(episode.image_path + "?tr=w-240,h-135,dpr-" + window.devicePixelRatio.toString())} width={240} height={135} loading="lazy" onError={(e) => {e.target.replaceWith(defaultImage(e.nativeEvent.target.style,240,135,"","css-y9s1zk"))}} />
                                        {episode.isNewEpisode == 1 && <Typography className={classes.newEpisodeText} gutterBottom variant="inherit">
                                            NOUVEAU
                                        </Typography>}
                                        {episode.start ? <div className={classes.watchContainer2}><div><div style={{ width: ( episode.start / episode.tTotalTime * 100 ) + "%" }}></div></div></div> : 
                                        episode.start == 0 ? <div className={classes.watchContainer2}><div><div></div></div></div> : <></>}
                                      </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs className={classes.episodeContainerElement}>
                                          <div className={classes.episodeTopElement}>
                                            <Typography className={classes.title + " " + classes.titleEpisode} gutterBottom variant="subtitle1" component="div">
                                              {episode.number}. {episode.titleEpisode}
                                              {currentUser && currentUser.statut != 20 && item.isFree != 1 && (episode.number != 1 || episode.season != 1) &&
                                                <div className={classes.freeLogo} style={{ marginLeft: 10 }}>
                                                  <i class="fa-solid fa-lock" title="Vous devez vous abonner pour regarder cet épisode"></i>
                                                </div>
                                              }
                                            </Typography>
                                            <Typography className={classes.timeEpisode} variant="subtitle1" component="div">
                                              {episode.time}
                                            </Typography>
                                          </div>
                                          <Typography variant="body2" gutterBottom className={classes.descriptionEpisode}>
                                            {episode.description}
                                          </Typography>
                                          {/*episode.isNewEpisode == 1 && <Typography style={{ background: "rgba(185,185,185,0.7)", color: "#FFF", padding: "5px 15px", width: "fit-content", borderRadius: 24 }} gutterBottom variant="inherit">
                                            NOUVEAU
                                          </Typography>*/}
                                          <Grid item>
                                          </Grid>
                                        
        
                                      </Grid>
                                      {/*<Grid item>
                                        <Typography style={{ color: "#CCCCCC", fontSize: 12 }} variant="subtitle1" component="div">
                                          {episode.time}
                                        </Typography>
                                      </Grid>*/}
                                    </Grid>
                                  </Grid>
                                </Paper>
        
        
        
        
                              )
                              )}
        
        
                            </TabPanel>
                          ))}
        
                          {/* {seasons.map(season => (
                            <TabPanel value={value} index={season.number} style={width > 1470 ? { width: 1320 } : width >= 840 ? { width: width - 170 } : { width: width - 30 }}>
                              <GridItem>
                                <GridList style={width >= 840 ? { paddingLeft: 15, overflow: "hidden" } : { overflow: "hidden", padding: 0 }}>
                                  {tabSeason.map(episode =>
                                  (episode.season == season.number &&
                                    <div style={width > 1470 ? { width: 1320, display: "flex", justifyContent: "space-between" } : width >= 840 ? { minWidth: width - 250, display: "flex", justifyContent: "space-between" } : { minWidth: width - 80, display: "flex", justifyContent: "space-between", padding: 0, margin: 0, height: 120 }}>
                                      <div style={width >= 840 ? { display: "flex", width: 370 } : { display: "flex", width: 190 }}>
                                        <img src={encodeURI(episode.image_path + "?tr=w-240,h-135")} style={width >= 840 ? { width: 200, height: 112.5, borderRadius: 20 } : { width: 100, height: 56.25, borderRadius: 10 }} alt={episode.image_path} />
                                        <div style={width >= 840 ? { marginTop: 7, marginLeft: 10 } : { marginLeft: 5 }}>
                                          <h6 style={width >= 840 ? { color: '#FFF' } : { color: '#FFF', marginBottom: 0 }}>{episode.number}. {episode.titleEpisode}</h6>
                                          <small style={{ color: "#FFF" }}>{episode.time}</small>
                                        </div>
                                      </div>
                                      <div style={width > 1470 ? { maxWidth: 1320 / 3 } : width >= 840 ? { maxWidth: (width - 250) / 3 } : { maxWidth: width / 3 }}>
                                        <p style={width < 840 ? { color: '#FFF', textAlign: "justify", fontSize: 12, maxHeight: 150 } : { color: '#FFF', textAlign: "justify" }}>{episode.description}</p>
                                      </div>
                                      <div style={{ minWidth: 110 }}>
                                        <Link style={{ margin: 8 }} to={{
                                          pathname: "/player",
                                          state: episode
                                        }} style={{ textDecoration: "none" }} >
                                          {width >= 840 ?
                                            <Button style={{ borderRadius: 25 }}
                                              color="sofa"
                                              size="lg"
                                              //  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
        
        
                                              <i className="fas fa-play" />  Lecture
        
                                            </Button> : (
                                              <Button style={{ borderRadius: 25 }}
                                                color="sofa"
                                                size="sg"
                                                //  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
        
        
                                                <i className="fas fa-play" />
        
                                              </Button>
                                            )}
                                        </Link>
                                      </div>
                                    </div>
                                  )
                                  )}
                                </GridList>
                              </GridItem>
                            </TabPanel>
                          ))} */}
                          {/* <BottomSheet
                                open = {mobilePopUp}
                                onDismiss = {onDismiss}
                            >
                                {mobilePopUp && <div className={classesList.mobilePopUp}>
                                    <button onClick={clickEvent}>
                                        <img src={logoApp} width={60} />
                                        <p>Voir sur l'application</p>
                                    </button>
                                </div>}
                            </BottomSheet> */}
                        </GridContainer>
                      </div>
        
        
                    </div>
                  </div>
                )
                }
                <Footer />
            </div >
        );
    }
}
