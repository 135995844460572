import React, { useEffect, useRef, useState } from "react";
// nodejs library that concatenates classes
import {
    BrowserRouter as Router,
    NavLink,
    Route,
    Link,
    useParams
} from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import Lightbox from 'react-image-lightbox';
//import 'react-image-lightbox/style.css'; 
// @material-ui/icons
import sofaLogo from "assets/img/sofa-blanc_final.png";
// core components


import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import useWindowSize from 'components/useWindowSize';
import exclusif from 'assets/img/exclusif.png';

import { loginAPI } from "API/LoginAPI.js";
import { getItemsHomePage } from "API/GetItemHomePageAPI.js";
import noImageAVIF from "assets/img/Fond_lampadaire_Portrait.avif";
import noImage from "assets/img/Fond_lampadaire_Portrait.jpg";

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(250, 167, 74, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);
//import 'assets/assets-home/js/main.js'
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    pictureDefault: {
        position: "relative"
    },
    textDefault: {
        color: "#FFF",
        fontSize: 20,
        fontFamily: "Bubblebody Light",
        textAlign: "center",
        position: "relative",
        top: "-50%"
    }
}));
export default function HomePageV2() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState('');
    const { width, height } = useWindowSize();
    const [callingAPI, setCallAPI] = useState(false);
    const [loading, setLoading] = useState(true);
    const [listItems, setListItems] = useState([]);
    const [listAvis, setListItemsAvis] = useState([]);
    const [autoScrollTimer, setAutoScrollTimer] = useState();
    const [returnTimer, setReturnTimer] = useState();
    const [leftScroll, setLeftScroll] = useState(false);
    const [lastPosition, setLastPosition] = useState(0);
    const [returnController, setReturnController] = useState(false);
    const timerRef = useRef();
    const leftScrollRef = useRef();
    const positionRef = useRef();
    const returnRef = useRef();
    const returnTimerRef = useRef();

    timerRef.current = autoScrollTimer;
    leftScrollRef.current = leftScroll;
    positionRef.current = lastPosition;
    returnRef.current = returnController;
    returnTimerRef.current = returnTimer;

    /*useEffect(() => {
        const getAPI = async () => {
            const name = "siteabout";
            const psw = "s1teS0fa2022";
            const respLogin = await loginAPI(name, psw);
            const token = respLogin.data.accessToken;
            const respItems = await getItemsHomePage(token);
            setListItems(respItems.data);
            setLoading(false);
            mousseScroll();
            if (width <= 768) {
                let element = document.getElementById('listText');
                let element2 = document.getElementById('listMobile');
                const heightElement = element.clientHeight;
                element2.setAttribute('style', "min-height: 350px;margin-bottom: " + heightElement.toString() + "px;");
            }
        }
        const callingAPIF = () => {
            window.removeEventListener('scroll', callingAPIF);
            setTimeout(() => {
                if (!callingAPI) {
                    setCallAPI(true);
                    getAPI();
                }    
            }, 10)
        }
        window.addEventListener('scroll', callingAPIF);
        if (width <= 768) {
            callingAPIF();
        }
        // let avis = [];
        // avis.push({
        //     imageAVIF: alexisAVIF,
        //     image: alexis,
        //     name: "Alexis Bonzi",
        //     text: "Tellement de films et séries à découvrir ! J'adore SOFA vod !"
        // }, {
        //     imageAVIF: kevinAVIF,
        //     image: kevin,
        //     name: "Kévin Graillot",
        //     text: "Depuis que je me suis abonné à SOFA vod je peux regarder des films depuis mon yacht."
        // }, {
        //     imageAVIF: guillaumeAVIF,
        //     image: guillaume,
        //     name: "Guillaume Pissot",
        //     text: "Grâce à SOFA vod j'ai été promu directeur !"
        // })
        // setListItemsAvis(avis);
    }, [])

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const mousseScroll = () => {
        const ele = document.getElementById('scrollList');

        let pos = { top: 0, left: 0, x: 0, y: 0 };

        const mouseDownHandler = function (e) {
            clearTimeout(timerRef.current);
            clearTimeout(returnTimerRef.current);
            ele.style.cursor = 'grabbing';
            ele.style.userSelect = 'none'

            pos = {
                left: ele.scrollLeft,
                // Get the current mouse position
                x: e.clientX
            };

            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);
        };

        const mouseMoveHandler = function (e) {
            // How far the mouse has been moved
            const dx = e.clientX - pos.x;

            // Scroll the element
            ele.scrollLeft = pos.left - dx;
        };

        const mouseUpHandler = function () {
            ele.style.cursor = 'grab';
            ele.style.removeProperty('user-select');
            setReturnTimer(setTimeout(() => {
                autoScroll();
                setReturnController(false);
            }, 2000))

            document.removeEventListener('mousemove', mouseMoveHandler);
            document.removeEventListener('mouseup', mouseUpHandler);
        };

        const autoScroll = function () {
            setAutoScrollTimer(setTimeout(() => {
                setLastPosition(ele.scrollLeft);
                if (!leftScrollRef.current) {
                    ele.scrollBy(1, 0);
                } else {
                    ele.scrollBy(-1, 0);
                }
                if (ele.scrollLeft === positionRef.current && !returnRef.current) {
                    clearTimeout(timerRef.current);
                    setReturnController(true);
                    clearTimeout(returnTimerRef.current);
                    setLeftScroll(!leftScrollRef.current);
                    setReturnTimer(setTimeout(() => {
                        autoScroll();
                        setReturnController(false);
                    }, 2000))
                } else if (!returnRef.current) {
                    autoScroll();
                }
            }, 20))
        }

        // Attach the handler
        ele.addEventListener('mousedown', mouseDownHandler);
        setAutoScrollTimer(setTimeout(() => {
            autoScroll();
        }, 2000))
    }

    useEffect(() => {
        return () => clearTimeout(autoScrollTimer);
    }, [autoScrollTimer])

    const defaultImage = (style, width, height, title, clas) => {
        let picture = document.createElement("picture");
        picture.setAttribute("class", classes.pictureDefault);
        let source = document.createElement("source");
        source.setAttribute("srcSet", noImageAVIF);
        source.setAttribute("type", "image/avif");
        let img = document.createElement("img");
        img.setAttribute("src", noImage);
        let jsonStyle = {};
        Object.values(JSON.parse(JSON.stringify(style))).forEach(element => {
          jsonStyle[element] = style[element];
        })
        if (JSON.stringify(jsonStyle).replace(/["'{}]/g, "").length > 0) {
            img.setAttribute("style", JSON.stringify(jsonStyle).replace(/","/g, ";").replace(/["'{}]/g, ""));
        }
        if (width) {
            img.setAttribute("width", width);
        }
        if (height) {
            img.setAttribute("height", height);
        }
        if (clas) {
            img.setAttribute("class", clas);
        }
        let text = document.createElement("h3");
        text.setAttribute("class", classes.textDefault);
        text.appendChild(document.createTextNode(title));
        picture.appendChild(source);
        picture.appendChild(img);
        picture.appendChild(text);
        return picture;
    }*/

    return (
        <div class="background-gradient" style={{ minHeight: "100vh", alignItems: "center", display: "flex" }}>
            <div class="container" style={{ color: "#FFF", fontSize: "18px" }}>
                <p>Bonjour à tous,</p>

                <p>Depuis 2019, nous travaillons pour mettre en avant le cinéma émergent français. Nous avons participé et soutenu des dizaines de festivals, nous avons été défendre la création auprès du CNC et du ministère de la culture, nous avons aussi pu présenter les œuvres et notre projet au festival de Cannes et à Vivatech, le plus grand salon européen dédié à la technologie.</p>
                <p>Mais surtout, nous avons rassemblé 550 œuvres et créateurs, 246h de contenu fictionnel, indépendant et français afin de pouvoir proposer le plus gros choix de films et séries dédié au cinéma émergent. Nous sommes fiers de compter aujourd’hui plus de 5000 utilisateurs sur notre plateforme et 1792h de contenu visionné depuis 2021.</p>

                <p>Je dois vous annoncer que SOFA vod ferme ses portes. Nous avons échoué à trouver un modèle économique viable et à convaincre un nombre suffisant d’utilisateurs. Nous ne pouvons plus financer le fonctionnement de SOFA comme nous l’avons fait durant ces années.</p>

                <p>L’entreprise SOFA vod est en liquidation judiciaire.</p>

                <p>Nous souhaitions remercier tous les créateurs qui ont joué le jeu et cru en nous dès le début. Qui ont accepté de nous confier leurs réalisations et qui nous ont fait confiance pour les mettre en avant.</p>
                <p>Nous voulions aussi montrer toute notre gratitude à nos partenaires, à toutes les personnes avec qui l’on a pu travailler, échanger et vivre de supers moments.</p>

                <p>Et enfin dire un grand merci à tous nos abonnés. Vous avez montré qu’il existait un public pour le cinéma émergent et vous avez permis à quelques créateurs de percevoir une rémunération pour leur travail.</p>

                <p>Encore merci et à bientôt pour d’autres aventures.</p>
            </div>
        </div>

    );
}