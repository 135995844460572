import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth.service";
import Button from "components/CustomButtons/Button.js";
import "./loginStyle.css";


const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Ce champ est obligatoire
      </div>
    );
  }
};

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
      message: "",
      width: this.props.width
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  nonSubscribedMessage = 
  <div style={{display: "flex", flexDirection: "column"}}>
    <p className="text-center">Vous n'avez pas d'abonnement actif</p>
    <div style={{display: "flex", justifyContent: "center"}}>
      <a className="linkButton" href="https://moncompte.sofavod.com/shop">
        Je m'abonne !
      </a>
    </div>
  </div>

  creatorMessage = 
  <div style={{display: "flex", flexDirection: "column"}}>
    <p className="text-center">Vous ne pouvez pas vous connectez au service avec un compte créateur</p>
    <div style={{display: "flex", justifyContent: "center"}}>
      <a className="linkButton" href="https://moncompte.sofavod.com/web/signup?redirect=https%3A%2F%2Fmoncompte.sofavod.com%2Fshop">
        Je crée un nouveau compte
      </a>
    </div>
    <small className="text-center">Vous devez utiliser une adresse e-mail différente</small>
  </div>

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {

      AuthService.login(this.state.username, this.state.password).then(response => {
        if (response.errorCode && response.errorCode != 2) {
          this.setState({
            loading: false,
            message: response.message
          })
        } else if (response.errorCode == 2) {
          this.setState({
            loading: false,
            message: this.nonSubscribedMessage
          });
        } else {
          const isUser = response.roles.findIndex(x => x == "user" || x == "creator") != -1 ? true : false;
          if (isUser && response.firstConnection) {
            this.props.navigation.push("/welcome");
          } else if (isUser) {
            this.props.navigation.push("/");
          } else {
            this.setState({
              loading: false,
              message: this.creatorMessage
            })
            AuthService.logout();
          }
        }
      }, error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          loading: false,
          message: resMessage
        });
      }).catch(err => {
        if (err.errorCode && err.errorCode != 2){
          this.setState({
            loading: false,
            message: err.message
          })
        } else if(err.errorCode == 2){
          this.setState({
            loading: false,
            message: this.nonSubscribedMessage
          });
        } else {
          this.setState({
            loading: false,
            message: err.message
          })
        }
      });
    } else {
      this.setState({
        loading: false
      });
    }
  }

  render() {
    return (
      <div className="col-md-12">
        <div style={{display: 'flex', marginTop:17,justifyContent: 'center',alignItems:'center'}}>
        <h4 className="title">Se connecter</h4>
         </div>
        <div >
         

          <Form
            onSubmit={this.handleLogin}
            ref={c => {
              this.form = c;
            }}
            style={{padding:25,marginTop:-10}}
          >
            <div className="form-group" >
              <label htmlFor="username">Adresse e-mail</label>
              <Input
                type="text"
                className="form-control"
                name="username"
                value={this.state.username}
                onChange={this.onChangeUsername}
                validations={[required]}
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">Mot de passe</label>
              <Input
                type="password"
                className="form-control"
                name="password"
                value={this.state.password}
                onChange={this.onChangePassword}
                validations={[required]}
              />
            </div>

            <div className="form-group" style={{marginTop: 10, display: "flex", justifyContent: "end"}}>
              <a href="https://moncompte.sofavod.com/web/reset_password" className="clickLink">Mot de passe oublié ?</a>
            </div>

            <div className="form-group"  style={{marginTop:30,justifyContent: 'center', display: "flex", flexDirection: "column"}}>
              <button
               className="btn btn-block"
               style={{margin: '0 auto', display: "flex"}}
              >
                    {this.state.loading && (
                  <span className="spinner-border spinner-border-sm" style={{alignSelf: "center"}}></span>
                )}
                          <Button style={{alignSelf:'center', paddingBottom: 10, paddingTop: 10, borderRadius: 10}}
                color={this.state.width > 768 ? "sofa" : "sofaM"}
                size="lg"
              //  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                target="_blank"
                rel="noopener noreferrer"
              >
                
              
            Se connecter
                  
              </Button>
              </button>
              <a href="https://moncompte.sofavod.com/web/signup?redirect=https%3A%2F%2Fmoncompte.sofavod.com%2Fshop" className="clickLink" style={{ textAlign: "center" }}>Je n'ai pas de compte ?</a>
            </div>

            {this.state.message && 
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {this.state.message}
                </div>
              </div>
            }
            <CheckButton
              style={{ display: "none" }}
              ref={c => {
                this.checkBtn = c;
              }}
            />
          </Form>
        </div>
      </div>
    );
  }
}
